@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #0d0a1e;
  color: white;
}

@layer components {
  .main-btn {
    @apply py-2 px-2 md:px-5 text-xs md:text-lg font-semibold rounded-full focus:outline-none focus:ring focus:ring-violet-400 focus:ring-opacity-75;
  }

  .demo-btn {
    @apply main-btn bg-cover bg-center;
    box-shadow: 0px 0px 0px 3px rgba(145, 108, 248, 0.17);
  }
}

.hero h2 {
  background: linear-gradient(
    0deg,
    #fff -4.78%,
    rgba(255, 255, 255, 0) 223.03%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  background: linear-gradient(
    180deg,
    #eaeaea 0%,
    rgba(234, 234, 234, 0) 321.67%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text {
  @apply bg-clip-text text-transparent;
  background-image: linear-gradient(
    180deg,
    rgba(234, 234, 234, 0.5) 0%,
    rgba(234, 234, 234, 0) 321.67%
  );
}

.whyUs-card {
  background: linear-gradient(
    180deg,
    rgba(28, 23, 56, 0.2) 0%,
    rgba(28, 23, 56, 0) 100%
  );

  border: 0.89px solid #1c1738;
  @apply rounded-lg backdrop-blur;
}

.blog-content {
  width: 100%;
}

.blog-content * {
  color: white;
}

.blog-content img {
  max-width: 70%;
  object-fit: contain;
  margin-inline: auto;
  height: auto !important;
}

pre {
  padding: 16px !important;
  font-size: 14px !important;
  border-radius: 0px 0px 12px 12px !important;
  background: rgba(143, 105, 248, 0.08) !important;
}
pre .comment {
  color: #fefefe !important;
  opacity: 0.25 !important;
  margin-right: 12px !important;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #0f0f1a;
}

::-webkit-scrollbar-track {
  background-color: rgba(15, 15, 26, 0.4);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #2d2d3d;
  border-radius: 4px;
  transition: background-color 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3d3d4f;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2d2d3d #0f0f1a;
}

::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: "absolute";
  width: "100%";
  height: "100%";
  cursor: "pointer";
}
/* --------------- */
/* Add this to your global CSS or create a new CSS module */
.markdown-content {
  /* Base styles */
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
  font-weight: 600;
}

.markdown-content p {
  margin-bottom: 1em;
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.markdown-content li {
  margin-bottom: 0.5em;
}

.markdown-content code {
  background-color: rgba(181, 255, 0, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}

.markdown-content pre {
  background-color: rgba(181, 255, 0, 0.1);
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
  margin-bottom: 1em;
}

.markdown-content blockquote {
  border-left: 4px solid #b5ff00;
  padding-left: 1em;
  margin-left: 0;
  margin-bottom: 1em;
  opacity: 0.8;
}

.markdown-content a {
  color: #b5ff00;
  text-decoration: underline;
}

.markdown-content a:hover {
  opacity: 0.8;
}

.markdown-content table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
}

.markdown-content table th,
.markdown-content table td {
  border: 1px solid rgba(181, 255, 0, 0.2);
  padding: 0.5em;
}

.markdown-content table th {
  background-color: rgba(181, 255, 0, 0.1);
}
